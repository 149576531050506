import simbolo from '../../../assets/simbolo-branco-1.png'

import styled from 'styled-components';

export const MainContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 3.125rem 0;
    flex-direction: column;
    border-radius: 1.625rem;
    justify-content: center;
    
    background-size: cover;
    background-color: #1D2129;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${simbolo});

    .ContainerTable {
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.9);
    }
`;

export const Title = styled.h2`
    color: #ffff;
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
    margin-bottom: 0.5rem;

    @media (max-width: 570px) {
        font-size: 1.5rem;
    }
`;

export const Subtitle = styled.p`
    margin-bottom: 2rem;
    padding: 1rem;
    
    color: #dfe4eb;
    font-size: 1.1rem;
    text-align: center;

    @media (max-width: 570px) {
        font-size: 1rem;
    }
`;


export const ContainerTable = styled.div`
    width: 100%;
    margin: auto;
    max-width: 1100px;
    border-radius: 1rem;
    background-color: #fff;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);

    @media (max-width: 926px) {
        border-radius: 0;
    }
`;

export const Table = styled.table`
    width: 100%;
    font-size: 1rem;
    border-collapse: collapse;
    font-family: 'lato', sans-serif;

    @media (max-width: 570px) {
        font-size: 1rem;
        table-layout: fixed;
    }
`;

export const TableHead = styled.thead`
    background-color: #f9f9f9;

    @media (max-width: 570px) {
        font-size: 0.9rem;
    }
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
    height: 51px;
    display: table-row;

    &:nth-child(even) {
        background-color: #f9f9f9;
    }

    @media (max-width: 570px) {
        font-size: 0.9rem;
        padding: 0.313rem;
    }
`;

export const TableHeader = styled.th`
    padding: 1.875rem;
    
    color: #333;
    font-weight: 900;
    font-size: 1.25rem;
    text-align: center;

    .icon{
        display: flex;
        justify-content: start;
    }

    :first-child {
        border-top-left-radius: 0.625rem;
    }

    :last-child {
        border-top-right-radius: 0.625rem;
    }

    @media (max-width: 570px) {
        font-size: 1rem;
        padding: 0.5rem;
    }
`;

export const TableCell = styled.td`
    padding: 0.6rem;
    
    color: #333;
    font-size: 14px;
    line-height: 14px;
    text-align: center;

    &:first-child {
        text-align: left;
        font-weight: 700;
        margin-right: 1.25rem;
        padding-left: 2rem;
    }

    @media (max-width: 570px) {
        padding: 0.5rem;
        font-size: 0.9rem;

        &:first-child{
            padding-left: 0.4rem;
            font-size: 0.770rem;
        }
    }
`;

export const SupportRow = styled.tr`
  background-color: white;

  @media (max-width: 330px) {
      display: flex;
      width: 100%;
      
      padding: 0;
      flex-direction: row;
      box-sizing: border-box;
      justify-content: space-around;
  }
`;
export const SupportTitle = styled.h4`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;

  @media (max-width: 330px) {
      display: none;
}
`;

export const SupportPlanName = styled.div`
  display: none;

  @media (max-width: 330px) {
      display: block;
      width: 100%;
      
      font-weight: bold;
      text-align: center;
      margin-bottom: 0.313rem;
    }
`;

export const SupportList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media (max-width: 330px) {
      width: 100%;
      display: flex;
      
      align-items: center;
      flex-direction: column;
  }
`;

export const SupportItem = styled.li`
  color: #666;
  font-size: 0.9rem;

  @media (max-width: 330px) {
      width: 100%;
      
      font-size: 0.8rem;
      text-align: center;
  }
`;

export const PriceRow = styled.tr`
    
    @media (max-width: 926px) {
        display: none;
    }
`;

export const PriceWithButtonContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 0.625rem;
    
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 926px) {
        width: 100%;
        padding: 0.625rem;
        max-width: 1000px;
    }

    @media (max-width: 570px) {
        padding: 0.5rem;
        max-width: 300px;
    }

    @media (max-width: 320px) {
        padding: 0.313rem;
        max-width: 250px;
    }
`;


export const MobilePriceContainer = styled.div`
    display: none;

    @media (max-width: 926px) {
        width: 100%;
        display: flex;
        padding: 0;
        
        flex-direction: row;
        justify-content: space-between;
        background-color: #f9f9f9;
    }
`;

export const MobilePriceBox = styled.div`
    display: flex;
    width: 100%;
    height: 9.375rem;
    max-width: 100%;
    padding: 0.625rem 0.25rem;
    
    text-align: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    background-color: ${props => (props.positionMiddle ? "#292E38" : "#303848")};

    @media (max-width: 570px) {
        height: 12.5rem;
    }
`;

export const MobilePriceBoxTitle = styled.h4`
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.625rem;

    @media (max-width: 926px) {
        font-size: 1rem;
    }
`;

export const Price = styled.div`
    font-size: ${({ isMobile }) => (isMobile ? '1.5rem' : '1.75rem')};
    font-weight: 800;
    color: ${({ isMobile }) => (isMobile ? '#FFFFFF' : '#21262F')};
    margin-bottom: ${({ isMobile }) => (isMobile ? '0px' : '8px')};
    
    @media (max-width: 386px) {
        font-size: ${({ isMobile }) => (isMobile ? '1.2rem' : '1.75rem')};
    }

    .currency-symbol {
         font-size: 1.2rem;
         vertical-align: baseline;
     }

    .cents {
        font-size: 1.2rem;
        vertical-align: baseline;
    }
`;

export const OldPrice = styled.div`
    font-size: ${({ isMobile }) => (isMobile ? '0.875rem' : '1rem')};
    color: #21262F;
    font-weight: 700;
    text-decoration: line-through;
    margin-bottom: 16px;
`;

export const NewPrice = styled.div`
    font-size: ${({ isMobile }) => (isMobile ? '1.5rem' : '1.75rem')};
    font-weight: 800;
    color: ${({ isMobile }) => (isMobile ? '#FF5252' : '#FF5252')};
    margin-bottom: 16px;

    @media (max-width: 386px) {
        font-size: ${({ isMobile }) => (isMobile ? '1.2rem' : '1.75rem')};
    }

    .currency-symbol {
         font-size: 1.2rem;
         vertical-align: baseline;
     }

    .cents {
        font-size: 1.2rem;
        vertical-align: baseline;
    }
`;

export const PriceDescription = styled.p`
    margin-top: 1px;
    font-size: 0.8rem;
    text-align: center;
    
    color: ${props => (props.isMobile ? "#BAC1CF" : "#21262F")};

    @media (max-width: 926px) {
        font-size: 0.8rem;
    }

    @media (max-width: 570px) {
        font-size: 0.7rem;
    }
`;

export const ButtonBelowPrices = styled.div`
    display: none;

    @media (max-width: 926px) {
        width: 97%;
        display: flex;
        
        justify-content: center;
        background-color: #fff;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }
`;

export const AdditionalText = styled.p`
    margin: 0;
    padding: 0.625rem 0;
    
    color: #555;
    font-size: 0.9rem;
    text-align: center;
    @media (max-width: 926px) {
        display: none;
    }
`;

export const MobileAdditionalTextContainer = styled.div`
    display: none;

    @media (max-width: 926px) {
        margin: 0;
        width: 97%;
        display: flex;
        padding: 1.25rem;
        
        color: #555;
        font-size: 0.9rem;
        text-align: center;
        box-sizing: border-box;
        background-color: #fff;
        justify-content: flex-start;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
`;

export const MobileAdditionalText = styled.p`
    color: #555;
    text-align: left;
    font-size: 0.9rem;
`;

export const PrePaidPlanContainer = styled.div`
    padding: 1.25rem;
    margin: 1.25rem;
    
    color: #03903E;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    border-radius: 0.5rem;
    box-sizing: border-box;
    background-color: #d4edda;
`;

export const MobilePlans = styled.h1`
    display: flex;
    color: white;
    font-size: 1.7rem;
`;